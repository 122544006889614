import Styled from 'vue3-styled-components';

const SettingWrapper = Styled.div`
    .setting-card-title{
        @media only screen and (max-width: 479px){
            text-align: center;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
        }
        span{
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            color: ${({theme}) => theme['light-color']};
        }
    }
`;

const ProfileAuthorBox = Styled.div`
    .ant-card-body{
        padding: 25px 0 25px !important;
    }
    .author-info{
        padding: 0 20px 20px;
        text-align: center;
        border-bottom: 1px solid ${({theme}) => theme['border-color-light']};
        .info{
            background-color: transparent;
        }
    }
    figure{
        position: relative;
        max-width: 120px;
        margin: 0 auto 18px;
        .ant-upload-select{
            position: absolute;
            ${({theme}) => (theme.rtl ? 'left' : 'right')}: 0;
            bottom: -2px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background: ${({theme}) => theme['primary-color']};
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
            i{
                color: #fff;
            }
        }
    }
    figcaption{
        .info{
            h1,
            h2,
            h3,
            h4,
            h5,
            h6{
                font-size: 18px;
                margin-bottom: 4px;
            }
            p{
                margin-bottom: 0;
                color: ${({theme}) => theme['light-color']};
            }
        }
    }

    .settings-menulist{
        padding: 20px 20px 0px 20px;
        li{
            a{
                display: flex;
                align-items: center;
                padding: 12px 20px;
                border-radius: 6px;
                color: ${({theme}) => theme['light-color']};
                i,
                svg,
                img{
                    ${({theme}) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
                }
                &.router-link-active{
                    font-weight: 500;
                    color: ${({theme}) => theme['primary-color']};
                    background: ${({theme}) => theme['primary-color']}05;
                }
            }
        }
    }
`;

const CheckListWrapper = Styled.div`
    .checklist-box-single{
        .ant-card{
            border: 1px solid ${({theme}) => theme['border-color-light']};
        }
        .checklist-body{
            box-shadow: 0 10px 30px ${({theme}) => theme['light-color']}10;
            .ant-card{
                margin-bottom: 0 !important;
            }
            .ant-card-body{
                padding: 5px 0 !important;
            }
            nav{
                li{
                    padding: 15px 25px !important;
                    @media only screen and (max-width: 575px){
                        padding: 15px 20px !important;
                    }
                    &:not(:last-child){
                        border-bottom: 1px solid ${({theme}) => theme['border-color-light']};
                    }
                }
            }
        }
        .checklist-single-item{
            .checklist-single-item__title{
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 2px;
                color: ${({theme}) => theme['gray-color']};
            }
            p{
                margin-bottom: 3px;
                color: ${({theme}) => theme['light-color']};
            }
        }
    }
`;

export {
  SettingWrapper,
  ProfileAuthorBox,
  CheckListWrapper
};
