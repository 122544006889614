<template>
  <profile-author-box>
    <sdCards headless>
      <div class="author-info">
        <figcaption>
          <div v-if="!$_.isEmpty(user)" class="info">
            <sdHeading as="h4">{{user.firstName + ' ' + user.lastName}}</sdHeading>
            <p>{{user._jv.relationships.company?.name}}</p>
          </div>
        </figcaption>
      </div>
      <nav class="settings-menulist">
        <ul>
          <li>
            <router-link :to="{name: `${routePrefix}UserBasicInfo`}">
              <sdFeatherIcons type="user" size="14" />
              {{$t('views.userProfile.title')}}
            </router-link>
          </li>
          <li v-if="user._jv && currentUser._jv?.id === user._jv.id && (companyApproved || isBackOfficeUser)">
            <router-link :to="{name: `${routePrefix}ChangePassword`}">
              <sdFeatherIcons type="key" size="14" />
              {{$t('views.userProfile.password')}}
            </router-link>
          </li>
          <li v-if="companyApproved || (isBackOfficeUser && user.type === 'BackOfficeUser') ">
            <router-link :to="{name: `${routePrefix}AccountPermissions`}">
              <sdFeatherIcons type="unlock" size="14" />
              {{$t('views.userProfile.permissions')}}
            </router-link>
          </li>
          <li v-if="$can('read', 'allHistory')">
            <router-link :to="{name: `${routePrefix}AccountHistory`}">
              <sdFeatherIcons type="file-text" size="14" />
              {{$t('views.userProfile.history')}}
            </router-link>
          </li>
        </ul>
      </nav>
    </sdCards>
  </profile-author-box>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {ProfileAuthorBox} from './style';

export default defineComponent({
  name: 'ProfileNavigation',
  components: {ProfileAuthorBox},
  setup() {
    const {getters, state} = useStore();
    const user = computed(() => getters.user);
    const companyApproved = computed(() => state.session.currentUser?._jv?.relationships?.company?.status === 'approved');
    const currentUser = computed(() => state.session.currentUser);
    const isBackOfficeUser = computed(() => state.session.userType === 'BackOfficeUser');
    const routePrefix = computed(() => {
      switch (currentUser.value.type) {
      case 'BackOfficeUser':
        return 'BackOffice';
      case 'ClientUser':
        return 'Client';
      default:
        return 'Agent';
      }
    });

    return {
      user,
      currentUser,
      companyApproved,
      routePrefix,
      isBackOfficeUser
    };
  }
});
</script>
