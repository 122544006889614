<template>
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="25">
        <a-col :xxl="6" :lg="8" :md="10" :xs="24">
          <profile-navigation />
        </a-col>
        <a-col :xxl="18" :lg="16" :md="14" :xs="24">
          <setting-wrapper>
            <router-view/>
          </setting-wrapper>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, watch
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {SettingWrapper} from '@/components/users/style';
import ProfileNavigation from '@/components/users/ProfileNavigation';
import _ from 'lodash';

export default defineComponent({
  name: 'CompanyUsers',
  components: {
    Main, ProfileNavigation, SettingWrapper
  },
  setup() {
    const route = useRoute();
    const {
      dispatch, state, getters
    } = useStore();
    const user = computed(() => getters.user);

    const getUser = () => {
      return dispatch('getUser', {
        id: route.params.userId === 'me' ? state.session.currentUser._jv.id : route.params.userId
      });
    };

    const routePrefix = computed(() => {
      switch (state.session.currentUser.type) {
      case 'BackOfficeUser':
        return 'BackOffice';
      case 'ClientUser':
        return 'Client';
      default:
        return 'Agent';
      }
    });

    watch(() => route.params.userId, () => {
      if (route.name === `${routePrefix.value}UserBasicInfo`) {
        dispatch('toggleLoading', true);
        getUser().then(() => dispatch('toggleLoading', false));
      }
    });

    const parentRoute = computed(() => {
      if (state.session.currentUser.type === 'BackOfficeUser' && _.includes(['ClientUser', 'AgentUser'], user.value.type)) {
        return {
          name: 'CompanyProfile', params: {companyId: user.value?._jv?.relationships?.company?._jv?.id}, title: 'views.company.details'
        };
      }
      return {
        name: `${routePrefix.value}Users`, title: 'views.users.title'
      };
    });

    const routes = computed(() => {
      return [{
        name: parentRoute.value.name,
        breadcrumbName: parentRoute.value.title,
        params: parentRoute.value.params
      }, {
        name: `${routePrefix.value}UserProfile`,
        params: {userId: route.params?.userId},
        breadcrumbName: route.params?.userId === 'me' ? 'views.userProfile.title' : 'models.user.entity'
      }];
    });

    dispatch('toggleLoading', true);
    const promises = [getUser()];
    if (state.session.currentUser?._jv.relationships?.company?.status === 'approved') promises.push(dispatch('getPermissions'));
    Promise.all(promises).then(() => {
      dispatch('toggleLoading', false);
    });

    return {
      routes
    };
  }
});
</script>
